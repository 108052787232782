.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header {
  box-shadow: 0 3px 10px rgba(0, 0, 0, .3);
}

hr {
  border-top: 1px solid rgba(205, 92, 92, 0.2) !important;
}

.ulk-form {
  margin-bottom: 50px;
}

.ulk-label {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.ulk-input {
  text-align: center;
  text-transform: uppercase;
  margin: auto;
  width: 300px;
  letter-spacing: 0.12em;
}

.data-loader {
  height: 20px;
}

.package-table-wrapper {
  margin-bottom: 30px;
  border: 2px solid #040059;
}

.package-table-wrapper h3 {
  text-align: left;
  padding: 3px 10px;
  color: #fff;
  margin-bottom: 0;
  background-color: #040059;
}

.package-table-wrapper .package-table-info {
  padding: 15px 3px 3px 15px;
  background-color: whitesmoke;
}

.package-table-wrapper .package-table-info p {
  font-size: .9em;
  text-align: left;
  margin: 0;
}

.package-table-wrapper .packages-table {
  font-size: 0.9em !important;
  border-top: 2px solid #040059;
  margin: 0;
}

.packages-table a {
  color: #040059;
}

.packages-table th,
.packages-table td {
  vertical-align: middle !important;
}

.packages-table thead th:first-child,
.packages-table tfoot th:first-child {
  border-right: 2px solid #040059;
}

.packages-table tbody th {
  border-right: 2px solid #040059;
}

.table-danger,
.table-danger>td,
.table-danger>th {
  background-color: rgba(255, 15, 79, .1) !important;
}

.badge-danger {
  background-color: #ff0d4d;
}

.text-danger {
  color: #ff0d4d !important;
}
